import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AltPageLayout, { isFirstSectionHasLabel } from 'Components/layout/AltPageLayout'
import Menu from 'Components/layout/Menu'
import ProjectPreview from 'Components/ProjectPreview/ProjectPreview'
import DynamicSections, {
  getDynamicSectionThemes,
} from 'Components/sections/DynamicSections'
import { ROUTES } from 'Utils/routing'
import { HeaderThemeOnScrollChanger } from 'Components/layout/Header'
import { useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'
import {
  CategoriesFilter,
  CategoriesFilterParams,
} from 'Components/filters/categories/CategoriesFilter'
import { FormSection } from 'Components/sections/FormSection'

const PortfolioPage = (props: PageDataType) => {
  const { data, path } = props

  const { t } = useTranslation()

  const isInit = useMaybeChangeLanguageOnInit()

  // console.log('PortfolioPage', data)

  if (!data || !isInit) {
    return null
  }

  const renderList = ({ selectedItems }: CategoriesFilterParams) => {
    return selectedItems.map((item, index) => (
      <ProjectPreview
        key={item.project.id}
        project={item.project}
        link={ROUTES.project(item.project.slug)}
        linkText={t('more') ?? ''}
        index={index + 1}
        totalCount={selectedItems.length}
        Component="section"
      />
    ))
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={data.seo}
        path={path}
        title={data.heading}
        subTitle={data.sub_heading}
        firstSectionHasLabel={isFirstSectionHasLabel(data.dynamic)}
      >
        <HeaderThemeOnScrollChanger
          themes={[
            ...getDynamicSectionThemes(data.dynamic),
            ...data.projects.map(() => 'dark'),
          ].map((i) => (i === 'light' ? '' : i))} // На светлой теме остаётся базовым цветом
        />
        <DynamicSections sections={data.dynamic} />
        <CategoriesFilter
          data={data}
          entityName="project"
          entitiesName="projects"
          categoryName="app_type"
          renderList={renderList}
        />
        <FormSection />
      </AltPageLayout>
    </>
  )
}

export default PortfolioPage
