import React, { ElementType, useCallback } from 'react'
import classNames from 'classnames'
import { ProjectBaseFragment } from 'Typings/generated-gatsby-types'
import BaseContainer from 'Components/BaseContainer/BaseContainer'
import Button from 'Components/touchables/Button'
import Text from 'Components/texts/Text'
import Row from 'Components/Row/Row'
import { fade, fadeDirection, FadeDirection, fadeUp } from 'Utils/animations'
import SectionIndex from 'Components/SectionIndex/SectionIndex'
import Touchable from 'Components/touchables/Touchable'
import { localImageUrl, withNewLines } from 'Utils/api'
import styles from './ProjectPreview.module.scss'

export type ProjectPreviewProps = {
  className?: string
  project: ProjectBaseFragment
  link?: string
  linkText?: string
  index?: number
  totalCount?: number
  showTexts?: boolean
  textsAnimations?: FadeDirection
  Component?: ElementType
}

const ProjectPreview = (props: ProjectPreviewProps) => {
  const {
    className = '',
    project,
    link = '',
    linkText = '',
    index,
    totalCount,
    showTexts,
    textsAnimations = 'up',
    Component = 'div',
  } = props

  const anim = useCallback((params?) => fadeDirection(textsAnimations, params), [
    textsAnimations,
  ])

  return (
    <Component className={classNames([styles.root, className])}>
      <Touchable
        className={styles.touchable}
        disabled={!link}
        link={link}
        styling="clear"
      >
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={localImageUrl(project.preview)}
            alt=""
            {...fadeUp()}
          />
          {project.show_overlay && <div className={styles.overlay} {...fadeUp()} />}
        </div>
        <BaseContainer
          className={styles.content}
          contentClassName={styles.contentInner}
          vertical="sm"
        >
          {(typeof showTexts !== 'boolean' || showTexts) && (
            <>
              <Text className={styles.workType} variant="sm" {...fade({ index: 0 })}>
                {project.work_type}
              </Text>
              <div className={styles.centerContent}>
                <Row className={styles.badges}>
                  <Button
                    className={styles.badge}
                    title={project.app_type}
                    theme="alt"
                    size="small"
                    rounded
                    {...fadeUp({ index: 2 })}
                  />
                  <Button
                    className={classNames([styles.badge, styles.years])}
                    title={project.years}
                    theme="alt"
                    size="small"
                    rounded
                    {...fadeUp({ index: 3 })}
                  />
                </Row>
                <Text className={styles.name} variant="heading" {...anim({ index: 0 })}>
                  {withNewLines(project.name)}
                </Text>
                {Boolean(project.description) && (
                  <Text className={styles.appInfo} variant="sm" {...anim({ index: 1 })}>
                    {project.description}
                  </Text>
                )}
                <Text
                  className={styles.shortInfo}
                  {...anim({ index: project.description ? 2 : 1 })}
                >
                  {project.short_info}
                </Text>
              </div>
            </>
          )}
          <Row className={styles.footer} {...anim()}>
            {link && linkText && (
              <Button
                className={styles.footerLink}
                title={linkText}
                variant="flat"
                theme="alt"
              />
            )}
            {totalCount && index && (
              <SectionIndex
                className={styles.footerIndex}
                index={index}
                totalCount={totalCount}
              />
            )}
          </Row>
        </BaseContainer>
      </Touchable>
    </Component>
  )
}

export default ProjectPreview
